<template>
    <b-container fluid>
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{$t('elearning_tpm.trainer_document')}} {{ $t('globalTrans.search') }}</h4>
          </template>
            <template v-slot:body>
                <b-row>
                  <b-col lg="5" sm="12">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="mobile"
                    >
                      <template v-slot:label>
                        {{ $t('globalTrans.mobile')}}
                      </template>
                      <b-form-input
                          type="number"
                          id="mobile"
                          v-model="search.mobile"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('elearning_config.organization')"
                        label-for="organization"
                    >
                      <v-select name="organization"
                                v-model="search.org_id"
                                label="text"
                                :reduce="item => item.value"
                                :options= orgList
                                :placeholder="$t('globalTrans.select')"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col lg="2" sm="12" class="text-right">
                      <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                  </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('elearning_tpm.trainer_document')}} {{ $t('globalTrans.list') }}</h4>
                    </template>
                    <template v-slot:headerAction1>

                    </template>
                    <template v-slot:headerAction>
                        <b-button class="btn_add_new mr-2" @click="pdfExport">
                          <i class="fas fa-print"></i>{{  $t('globalTrans.print') }}
                        </b-button>
                        <b-button class="btn_add_new" v-b-modal.modal-4 @click="resetId">
                          <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="bg-primary" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                      <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                      </template>
                                      <template v-slot:cell(mobile)="data">
                                        {{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(data.item.mobile, { useGrouping: false }) }}
                                      </template>
                                      <template v-slot:cell(status)="data">
                                        <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                        <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                      </template>
                                      <template v-slot:cell(action)="data">
                                        <a href="javascript:" class="btn_table_action table_action_view" title="View Details" v-b-modal.modal-5 @click="details(data.item)"><i class="fas fa-eye "></i></a>
                                        <a href="javascript:" class="btn_table_action table_action_edit" v-b-modal.modal-4  @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></a>
                                        <span v-if="data.item.status === 1">
                                                <a href="javascript:" class="btn_table_action table_action_status" @click="remove(data.item)"><i class="fas fa-toggle-on"></i></a>
                                            </span>
                                        <span v-if="data.item.status === 2">
                                                <a href="javascript:" class="btn_table_action table_action_toggle" @click="remove(data.item)"><i class="fas fa-toggle-off"></i></a>
                                            </span>
                                      </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
        <b-modal id="modal-5" size="lg" :title="$t('elearning_tpm.trainer_document') + ' ' + this.$t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <b-button @click="pdfExportDetails" class="ml-4 btn-success water-test-pdf-button">
                <i class="fas fa-print"></i> {{  $t('globalTrans.print') }}
            </b-button>
          <Details :item="item" :key="id" ref="details"></Details>
      </b-modal>

    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import Form from './Form'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { trainerProfileList, trainerProfileToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import ExportPdf from './export_pdf_details'
import Details from './Details'

export default {
    mixins: [ModalBaseMasterList],
    components: {
      Form, Details
    },
    data () {
      return {
        search: {
          org_id: 0,
          mobile: ''
        },
        id: 0,
        item: ''
      }
    },
    computed: {
      orgList: function () {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
      },
      formTitle () {
        return (this.editItemId === 0) ? this.$t('elearning_tpm.trainer_document') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_tpm.trainer_document') + ' ' + this.$t('elearning_config.update')
      },
      columns () {
        const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('globalTrans.name'), class: 'text-center' },
          { label: this.$t('elearning_config.organization'), class: 'text-center' },
          { label: this.$t('globalTrans.designation'), class: 'text-center' },
          { label: this.$t('globalTrans.mobile'), class: 'text-center' },
          { label: this.$t('elearning_tpm.working_field'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center w-15' }
        ]

        let keys = []

        if (this.$i18n.locale === 'bn') {
            keys = [
            { key: 'index' },
            { key: 'name_bn' },
            { key: 'org_bn' },
            { key: 'designation_bn' },
            { key: 'mobile' },
            { key: 'working_field_bn' },
            { key: 'status' },
            { key: 'action' }
            ]
        } else {
            keys = [
            { key: 'index' },
            { key: 'name' },
            { key: 'org' },
            { key: 'designation' },
            { key: 'mobile' },
            { key: 'working_field' },
            { key: 'status' },
            { key: 'action' }
            ]
        }

        return labels.map((item, index) => {
            return Object.assign(item, keys[index])
        })
      }
    },
    async created () {
        this.search = Object.assign({}, this.search, {
            org_id: this.$store.state.dataFilters.orgId
        })
        this.loadData()
    },
    mounted () {
      core.index()
    },
    methods: {
      async searchData () {
        this.loadData()
      },
      details (item) {
          this.item = item
      },
      remove (item) {
        this.changeStatus(trainingElearningServiceBaseUrl, trainerProfileToggleStatus, item, 'training_e_learning', 'trainerEvaluationList')
      },
      loadData () {
            const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(trainingElearningServiceBaseUrl, trainerProfileList, params).then(response => {
            if (response.success) {
                this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
                this.paginationData(response.data)
            }
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        getCustomDataList (data) {
            const listData = data.map(item => {
            const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
            const orgData = {}
            if (typeof orgObj !== 'undefined') {
                orgData.org = orgObj.text_en
                orgData.org_bn = orgObj.text_bn
            } else {
                orgData.org = ''
                orgData.org_bn = ''
            }
            const designationObj = this.$store.state.commonObj.designationList.find(designation => designation.value === parseInt(item.designation_id))
            const designationData = {}
            if (typeof designationObj !== 'undefined') {
                designationData.designation = designationObj.text_en
                designationData.designation_bn = designationObj.text_bn
            } else {
                designationData.designation = ''
                designationData.designation_bn = ''
            }

            return Object.assign({}, item, orgData, designationData)
            })
            return listData
        },
        pdfExportDetails () {
            this.$refs.details.pdfExport()
        },
        pdfExport () {
            const reportTitle = this.$i18n.locale === 'en' ? 'Trainer Profiles' : 'প্রশিক্ষকের প্রোফাইল'
            ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this)
        }
    }
}
</script>
